<template>
  <div class="withdrawalBox">
    <van-nav-bar :border="false" class="navBarBox" fixed left-arrow right-text="提现记录" title="提现" @click-right="$router.push('record?type=2')" @click-left="$router.back()"/>
    <div class="countBox">
      <div class="priceBox">
        <p class="txt1">可提现余额</p>
        <p class="price1">
          <span class="p1">{{ getPrice(info.balance)[0] }}.</span>
          <span class="p2">{{ getPrice(info.balance)[1] }}USDT</span>
        </p>
        <p class="p3">≈{{ withdraw.cny || '0.00000' }}CNY</p>
      </div>
      <div class="filedBox">
        <van-field v-model="txt" :placeholder="placeholder" class="field" readonly @click="checkFunc()">
          <template v-if="bindList.length<=0" #right-icon>
            <span class="txt">添加收款方式</span>
          </template>
        </van-field>
      </div>
      <div class="filedBox">
        <p class="txt">提现金额(含手续费≈{{ withdraw.fee }}%)</p>
        <van-field v-model="amount" class="field" placeholder="请输入提现金额">
          <template #right-icon>CNY</template>
        </van-field>
      </div>
      <div class="filedBox">
        <p class="txt">资金密码</p>
        <van-field v-model="password" class="field" placeholder="请输入资金密码" type="password"/>
      </div>
      <div class="cutBtn" @click="withdrawDo()">确定</div>
      <p class="text">提现须知：
        <span v-html="withdraw.withdraw_info"></span>
      </p>
    </div>
    <van-popup v-model="show" position="bottom" round>
      <van-picker :columns="bindList" show-toolbar @cancel="show = false" @confirm="onConfirm"/>
    </van-popup>
  </div>
</template>
<script>
import {getPrice} from "@/plugins/func";

export default {
  data() {
    return {
      show: false,
      value: '',
      info: {},
      withdraw: {},
      amount: '',
      password: '',
      type: '',
      txt: '',
      bindList: [],
      placeholder: '请添加收款方式'
    }
  },
  computed: {},
  watch: {},
  methods: {
    getPrice,
    getMemberInfo() {
      this.$post({
        url: `/app/member/memberInfo`,
        loading: true,
        success: (res) => {
          this.info = res.data
          this.getInfo()
        },
        tip: () => {
        }
      })
    },
    getInfo() {
      this.$post({
        url: `/app/withdraw/getInfo`,
        loading: true,
        success: (res) => {
          this.withdraw = res.data
          this.getQueryBind()
        },
        tip: () => {
        }
      })
    },
    getQueryBind() {
      this.$get({
        url: `/app/pay_collection/queryBind`,
        loading: true,
        success: (res) => {
          for (const resKey in res.data) {
            if (res.data[resKey].status != 0) {
              let u = {'ali': '支付宝', 'wx': '微信', 'bank': '银行卡', 'wallet': '钱包'}[resKey]
              this.bindList.push(u)
            }
          }
          if (this.bindList.length <= 0) this.$toast('请添加收款方式')
          if (this.bindList.length > 0) this.placeholder = '请选择收款方式'
        },
        tip: () => {
        }
      })
    },
    withdrawDo() {
      let data = {
        amount: this.amount,
        password: this.password,
        type: this.type,
      }
      this.$post({
        url: '/app/withdraw/withdrawDo',
        data,
        loading: true,
        success: (res) => {
          this.$toast('提现成功')
          this.getMemberInfo()
          this.txt = ''
          this.amount = ''
          this.password = ''
          this.type = ''
        },
        tip: () => {
        }
      })
    },
    onConfirm(e) {
      this.txt = e
      this.type = {'支付宝': 1, '微信': 2, '银行': 3, '钱包': 4}[e]
      this.show = false
    },
    checkFunc() {
      if (this.bindList.length <= 0) return this.$router.push('payment')
      // console.log(this.bindList)
      this.show = true
    },
  },
  created() {
    this.getMemberInfo()
  },
  mounted() {
  },
}
</script>

<style lang="less" scoped>
.withdrawalBox {
  min-height: 100vh;
  background-color: #000000;

  ::v-deep(.navBarBox) {
    background-color: #000000;

    .van-icon {
      color: #FFFFFF;
    }

    .van-ellipsis {
      color: #FFFFFF;
    }

    .van-nav-bar__text {
      color: #FFFFFF;
    }
  }

  .countBox {
    padding: 70px 15px 30px;

    .priceBox {
      padding: 24px;
      background: linear-gradient(180deg, #F58C89 0%, #F6998B 16%, #FDDC96 100%);
      border-radius: 25px;

      .txt1 {
        font-weight: 500;
        font-size: 13px;
        color: #333333;
      }

      .price1 {
        color: #000000;
        font-weight: bold;

        .p1 {
          font-size: 36px;
        }

        .p2 {
          font-size: 26px;
        }
      }

      .p3 {
        font-weight: bold;
        font-size: 16px;
        color: #000000;
      }
    }

    .filedBox {
      margin-top: 15px;
      display: flex;
      flex-direction: column;

      .txt {
        font-weight: bold;
        font-size: 16px;
        color: #FFFFFF;
        margin-bottom: 14px;
      }

      ::v-deep(.field) {
        background-color: #343434;
        border-radius: 15px;
        border: 1px solid #FFFFFF82;

        .van-field__control {
          color: #FFFFFF;
        }

        .txt {
          font-weight: 500;
          font-size: 14px;
          color: #89E94B;
        }
      }
    }

    .cutBtn {
      background: linear-gradient(180deg, #83E441 0%, #9AEF5E 100%);
      border-radius: 15px;
      padding: 15px 0;
      text-align: center;
      font-weight: 500;
      font-size: 15px;
      color: #000000;
      margin: 62px 0 40px;
    }

    .text {
      font-weight: 500;
      font-size: 13px;
      color: #999999;
    }
  }
}
</style>
