<template>
  <div class="container">
    <nav-bar title="用户协议" left-arrow />
    <div class="htmlcontentbox content" v-html="list" v-if='list'></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: `左手交易手续费，右手项目上币费，在疯狂增长的数字货币市场，交易所毋容置疑是最大赢家，许多创业者对其趋之若鹜。同时，黑客针对交易所的安全攻击越来越多，监管所带来的不确定性也越来越大。
     左手交易手续费，右手项目上币费，在疯狂增长的数字货币市场，交易所毋容置疑是最大赢家，许多创业者对其趋之若鹜。同时，黑客针对交易所的安全攻击越来越多，监管所带来的不确定性也越来越大。`,
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    getinfo() {
      this.$get({
        loading: true,
        noToken: true,
        url: "/app/auth/agreement",
        success: (res) => {
          this.list = res.data.content
        },
        tip: () => { },
      })
    },
  },
}
</script>
<style scoped lang="less">
.container {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  padding: 0 15px;
  background: #000;

  ::v-deep(.van-nav-bar) {
    background-color: #000000;

    .van-icon {
      color: #FFFFFF !important;
    }

    .van-ellipsis {
      color: #FFFFFF !important;
    }

    .van-nav-bar__text {
      color: #FFFFFF !important;
    }
  }


  .content {
    width: 100%;
    background: #000;
    border-radius: 10px;
    padding: 10px 14px;

    font-size: 14px;

    :deep(*) {
      color: #fff !important;
    }
  }
}
</style>
