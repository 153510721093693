<template>
  <div class="box">
    <van-nav-bar :border="false" class="navBarBox" fixed left-arrow title="修改昵称" @click-left="$router.back()" />
    <div style="height:30vw"></div>
    <div class="filedListBox">
      <van-field v-model="nickname" :border="false" class="fieldBox" label="昵称" />

    </div>
    <div style="height:50vw"></div>
    <div class="btn" @click="save">修改</div>


  </div>
</template>
<script>
export default {
  data() {
    return {
      /** 昵称 */
      nickname: "",
      /** 提交按钮禁用防止连点 */
      disabled: "",
    }
  },
  created() {
    this.get_info()
  },
  methods: {
    get_info() {
      this.$post({
        url: "/app/member/memberInfo",
        loading: true,
        data: {
        },
        success: (res) => {
          this.nickname = res.data.nickname
        },
        tip: () => {

        },
      })
    },
    save() {
      // 判断昵称是否为空
      if (!this.nickname) return this.$toast("请输入您的昵称")

      if (this.disabled) return

      this.disabled = true

      // 修改密码
      this.$post({
        url: "/app/member/editNick",
        data: {
          nickname: this.nickname,
        },
        success: () => {
          this.$toast("修改成功")
          setTimeout(() => {
            this.goBack()
            this.disabled = false
          }, 1500)
        },
        tip: (val) => {
          setTimeout(() => {
            this.disabled = false
          }, 1500)
        },
      })
    },
  },
}
</script>
<style lang='less' scoped>
.box {
  width: 100%;
  min-height: 100vh;
  background: #000;
  padding: 4vw;

  ::v-deep(.van-nav-bar) {
    background-color: #000000;

    .van-icon {
      color: #FFFFFF !important;
    }

    .van-ellipsis {
      color: #FFFFFF !important;
    }

    .van-nav-bar__text {
      color: #FFFFFF !important;
    }
  }
}

.filedListBox {
  display: flex;
  flex-direction: column;


  ::v-deep(.fieldBox) {
    background-color: transparent;
    // border-bottom: 1px solid #F2F2F2;
    padding: 15px;
    background-color: #343434;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 8px;

    .van-cell__title {
      color: #FFFFFF;
    }

    .van-cell__value {
      .van-field__control {
        color: #FFFFFF;
      }

      .btn {
        padding: 0 8px;
        font-weight: 500;
        font-size: 12px;
        color: #FFFFFF;
        background: #343434;
        border-radius: 100px;
        // border: 1px solid rgba(255, 255, 255, 0.5);
      }
    }
  }
}

.btn {
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  padding: 10px 0;
  background: linear-gradient(180deg, #83E441 0%, #9AEF5E 100%);
  border-radius: 6px;
  text-align: center;
  margin-bottom: 10px;
}
</style>
