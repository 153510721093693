import Vue from 'vue'
import App from './App'
import router from './router/index'
import 'vant/lib/index.css';
import './assets/css/reset.css';
import http from './request/http'
Vue.use(http)
//常规验证码
// import Verify from 'vue2-verify'
// Vue.component('Verify', Verify)
import { i18n } from './utils/lang'

Vue.config.productionTip = false;
new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
