<template>
  <div class='loginBox'>
    <div class="topBox">
      <div class="topImg">
        <div class="left">
          <div class="txt">Hi</div>
          <div class="txt">欢迎注册</div>
        </div>
        <img alt="" class="topImg" src="@/assets/img/loginBg1.png">
      </div>
      <div class="gao1" />
      <div class="filedListBox">
        <van-field v-model="phone" :border="false" class="fieldBox" label="手机号" maxlength="11" placeholder="请输入手机号"
          type="tel" />
        <van-field v-model="sms_code" :border="false" class="fieldBox" label="验证码" placeholder="请输入验证码" type="tel">
          <template #button>
            <send-code class="code" api="/app/auth/sendSms" :send-tell="{ 'phone': phone }"
              :send-scene="{ 'scene': 'register' }" :no-token="true" color="#83E441" round>
            </send-code>
          </template>
        </van-field>
        <van-field v-model="password" :border="false" class="fieldBox" label="登录密码" placeholder="请输入6-12位数字+字母密码"
          type="password" />
        <van-field v-model="re_password" :border="false" class="fieldBox" label="重复密码" placeholder="请输入6-12位数字+字母密码"
          type="password" />
        <van-field v-model="second_password" :border="false" class="fieldBox" label="资金密码" placeholder="请输入二级密码(6位纯数字)"
          type="password" />
        <van-field v-model="re_second_password" :border="false" class="fieldBox" label="重复密码"
          placeholder="请输入二级密码(6位纯数字)" type="password" />
        <van-field v-model="invite_code" :readonly="readonly" :border="false" class="fieldBox" label="邀请码" type="tel"
          placeholder="请输入邀请码(必填)" />
      </div>
    </div>
    <div class="btnBox">
      <div class="btn" @click="loginBtn">注册</div>
      <div class="toRegister" @click="goReplace('login')">返回登录</div>
      <div class="proto">
        <img v-if="check" @click="check = !check" class="checkImg" src="@/assets/img/check1.png">
        <img v-else @click="check = !check" class="checkImg" src="@/assets/img/check2.png">
        <div class="txt1">同意</div>
        <div class="txt2" @click="router('agreement')">《用户注册协议》</div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  components: {},
  data() {
    return {
      /** 手机号 */
      phone: "",
      /** 短信验证码 */
      sms_code: "",
      /** 密码 */
      password: "",
      /** 确认密码 */
      re_password: "",
      /** 交易密码 */
      second_password: "",
      /** 确认交易密码 */
      re_second_password: "",
      /** 邀请码 */
      invite_code: "",
      /** 邀请码只读 */
      readonly: false,
      /** 是否同意用户协议 */
      check: false,
      /** 提交按钮禁用防止连点 */
      disabled: false,
    }
  },
  created() {
    /** 🥣获取URL参数 自动映射data里面 */
    this.setUrlData()
    this.readonly = !!this.invite_code
  },
  methods: {
    loginBtn() {
      // 手机号
      if (!this.phone) return this.$toast("请输入手机号");
      // 短信验证码
      if (!this.sms_code) return this.$toast("请输入短信验证码");
      // 密码
      if (!this.password) return this.$toast("请输入密码");
      // 确认密码
      if (!this.re_password) return this.$toast("请再次输入密码以确认");
      // 交易密码
      if (!this.second_password) return this.$toast("请输入交易密码");
      // 确认交易密码
      if (!this.re_second_password) return this.$toast("请再次输入交易密码以确认");
      // 邀请码（可选，如果必填则使用下面的提示）
      if (!this.invite_code) return this.$toast("请输入邀请码");
      // 是否同意用户协议
      if (!this.check) return this.$toast("请先阅读并同意用户协议");

      if (this.disabled) return

      this.disabled = true

      this.$post({
        url: "/app/auth/register",
        loading: true,
        noToken: true,
        data: {
          phone: this.phone,
          sms_code: this.sms_code,
          password: this.password,
          re_password: this.re_password,
          second_password: this.second_password,
          re_second_password: this.re_second_password,
          invite_code: this.invite_code,
        },
        success: (res) => {
          this.toast({
            message: "注册成功",
            onClose: () => {
              if (this.readonly) {
                this.goReplace("download")
              } else {
                this.goReplace("login")
              }
              setTimeout(() => {
                this.disabled = false
              }, 1500)
            },
          })
        },
        tip: () => {
          setTimeout(() => {
            this.disabled = false
          }, 1500)
        },
      })
    },
  }
}
</script>
<style lang='less' scoped>
.loginBox {
  width: 100%;
  min-height: 100vh;
  padding: 10px 15px 30px;
  background-color: #000000;

  .topBox {
    padding: 50px 20px;

    .topImg {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        font-weight: bold;
        font-size: 30px;
        color: #FFFFFF;
      }

      .topImg {
        width: 93px;
        height: 109px;
      }
    }

    .gao1 {
      height: 50px;
    }

    .filedListBox {
      display: flex;
      flex-direction: column;
      gap: 20px 0;

      ::v-deep(.fieldBox) {
        background-color: transparent;
        border-bottom: 1px solid #F2F2F2;

        .van-cell__title {
          color: #FFFFFF;
        }

        .van-cell__value {
          .van-field__control {
            color: #FFFFFF;
          }

          .btn {
            padding: 0 8px;
            font-weight: 500;
            font-size: 12px;
            color: #FFFFFF;
            background: #343434;
            border-radius: 100px;
            border: 1px solid rgba(255, 255, 255, 0.5);
          }
        }
      }
    }
  }

  :deep(.code) {
    margin-right: -15px;
    margin-top: -4px;

    .van-button__content {
      color: #000 !important;
    }

    .van-button--mini {
      padding: 0 10px;
    }

  }

  .btnBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px 0;

    .btn {
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      color: #000000;
      padding: 10px 0;
      background: linear-gradient(180deg, #83E441 0%, #9AEF5E 100%);
      border-radius: 6px;
      text-align: center;
      margin-bottom: 10px;
    }

    .toRegister {
      font-weight: 500;
      font-size: 14px;
      color: #FFFFFF;
    }

    .proto {
      display: flex;
      align-items: center;
      gap: 0 5px;
      font-weight: 400;
      font-size: 12px;

      .checkImg {
        width: 11px;
        height: 11px;
      }

      .txt1 {
        color: #999999;
      }

      .txt2 {
        color: #86E545;
      }
    }
  }
}
</style>
