<template>
  <div class="mineBox">
    <p class="title">我的</p>
    <div class="userBox">
      <img :src="httpPath + info.head_img" alt="" class="userImg">
      <p class="userMsg">
        <span class="user">
          {{ info.nickname }}
          <img v-if="info.level_id==1" alt="" class="levelImg" src="@/assets/img/lv1.png">
          <img v-if="info.level_id==2" alt="" class="levelImg" src="@/assets/img/lv2.png">
          <img v-if="info.level_id==3" alt="" class="levelImg" src="@/assets/img/lv3.png">
        </span>
        <span class="mobile">{{ info.phone }}</span>
      </p>
    </div>
    <div class="listBox">
      <div class="list" @click="router('team')">
        <p class="left">
          <img alt="" class="img" src="@/assets/img/mine1.png">
          <span class="name">我的团队</span>
        </p>
        <img alt="" class="toMore" src="@/assets/img/icon7.png">
      </div>
      <div class="list" @click="$router.push('alertPassword?type=1')">
        <p class="left">
          <img alt="" class="img" src="@/assets/img/mine2.png">
          <span class="name">修改资金密码</span>
        </p>
        <img alt="" class="toMore" src="@/assets/img/icon7.png">
      </div>
      <div class="list" @click="$router.push('alertPassword?type=2')">
        <p class="left">
          <img alt="" class="img" src="@/assets/img/mine3.png">
          <span class="name">修改登录密码</span>
        </p>
        <img alt="" class="toMore" src="@/assets/img/icon7.png">
      </div>
      <div class="list" @click="$router.push('share')">
        <p class="left">
          <img alt="" class="img" src="@/assets/img/mine4.png">
          <span class="name">推广二维码</span>
        </p>
        <img alt="" class="toMore" src="@/assets/img/icon7.png">
      </div>
      <div class="list" @click="router('mineNews')">
        <p class="left">
          <img alt="" class="img" src="@/assets/img/mine5.png">
          <span class="name">个人信息</span>
        </p>
        <img alt="" class="toMore" src="@/assets/img/icon7.png">
      </div>
      <div class="list" @click="router('payment')">
        <p class="left">
          <img alt="" class="img" src="@/assets/img/mine6.png">
          <span class="name">收款方式</span>
        </p>
        <img alt="" class="toMore" src="@/assets/img/icon7.png">
      </div>
    </div>
    <div class="btn" @click="logOut()">退出登录</div>
  </div>
</template>
<script>
import Vue from "vue";

export default {
  data() {
    return {
      info: {},
      leImg: '',
    }
  },
  created() {
    this.getMemberInfo()
  },
  methods: {
    getMemberInfo() {
      this.$post({
        url: `/app/member/memberInfo`,
        loading: true,
        success: (res) => {
          this.info = res.data
          // this.leImg = ['','../../assets/img/lv1.png','../../assets/img/lv2.png','../../assets/img/lv3.png'][res.data.level_id]
        },
        tip: () => {
        }
      })
    },
    logOut() {
      this.$post({
        url: `/app/member/logout`,
        loading: true,
        success: (res) => {
          this.$toast(res.msg)
          setTimeout(() => {
            this.goReplace("/login")
            Vue.prototype.local.del("token");
          }, 1000)
          console.log(res)
        },
        tip: () => {
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.mineBox {
  width: 100%;
  min-height: 100vh;
  background-color: #000000;
  background-image: url("../../assets/img/mineBg1.png");
  background-size: contain;
  background-repeat: no-repeat;
  padding: 35px 15px 70px;

  .title {
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
  }

  .userBox {
    margin-top: 34px;
    display: flex;
    align-items: center;
    gap: 0 10px;

    .userImg {
      width: 68px;
      height: 68px;
      border-radius: 50%;
      border: 1px solid #FFFFFF;
    }

    .userMsg {
      color: #FFFFFF;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      gap: 4px 0;

      .user {
        font-size: 18px;

        .levelImg {
          width: 25px;
          height: 25px;
        }
      }

      .mobile {
        font-size: 13px;
      }
    }
  }

  .listBox {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px 0;

    .list {
      padding: 15px;
      background-color: #343434;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 8px;

      .left {
        display: flex;
        align-items: center;
        gap: 0 10px;

        .img {
          width: 18px;
          height: 18px;
        }

        .name {
          font-weight: 500;
          font-size: 15px;
          color: #FFFFFF;
        }
      }

      .toMore {
        width: 10px;
        height: 10px;
      }
    }
  }

  .btn {
    padding: 15px 0;
    font-weight: 500;
    font-size: 15px;
    color: #000000;
    background: linear-gradient(180deg, #83E441 0%, #9AEF5E 100%);
    border-radius: 15px;
    text-align: center;
    margin-top: 60px;
  }
}
</style>
