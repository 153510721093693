<template>
  <div class='loginBox'>
    <div class="topBox">
      <div class="topImg">
        <div class="left">
          <div class="txt">Hi</div>
          <div class="txt">欢迎登录</div>
        </div>
        <img alt="" class="topImg" src="@/assets/img/loginBg1.png">
      </div>
      <div class="gao1" />
      <div class="filedListBox">
        <van-field v-model="phone" :border="false" class="fieldBox" label="手机号" maxlength="11" type="tel" />
        <van-field v-model="password" :border="false" class="fieldBox" label="登录密码" type="password" />
      </div>
    </div>
    <div class="toPassword"><span @click="$router.push('password')">忘记密码</span></div>
    <div class="btnBox">
      <div class="btn" @click="loginBtn()">登录</div>
      <div class="toRegister" @click="$router.push('register')">没有账号，去注册</div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      /** 手机号 */
      phone: "",
      /** 登录密码 */
      password: "",
      /** 提交按钮禁用防止连点 */
      disabled: false,
    }
  },
  created() { },
  methods: {
    loginBtn() {
      if (!this.phone) return this.$toast("请输入手机号")
      if (!this.password) return this.$toast("请输入登录密码")

      if (this.disabled) return
      this.disabled = true

      this.$post({
        url: "/app/auth/login",
        loading: true,
        noToken: true,
        data: {
          phone: this.phone,
          password: this.password,
        },
        success: (res) => {
          this.local.set("token", res.data.token)
          this.toast({
            message: "登录成功",
            onClose: () => {
              this.goReplace("/")
              setTimeout(() => {
                this.disabled = false
              }, 1500)
            },
          })
        },
        tip: () => {
          setTimeout(() => {
            this.disabled = false
          }, 1500)
        },
      })
    },
  }
}
</script>
<style lang='less' scoped>
.loginBox {
  width: 100%;
  min-height: 100vh;
  padding: 10px 15px;
  background-color: #000000;

  .topBox {
    padding: 50px 20px;

    .topImg {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        font-weight: bold;
        font-size: 30px;
        color: #FFFFFF;
      }

      .topImg {
        width: 93px;
        height: 109px;
      }
    }

    .gao1 {
      height: 50px;
    }

    .filedListBox {
      display: flex;
      flex-direction: column;
      gap: 20px 0;

      ::v-deep(.fieldBox) {
        background-color: transparent;
        border-bottom: 1px solid #F2F2F2;

        .van-cell__title {
          color: #FFFFFF;
        }

        .van-cell__value {
          .van-field__control {
            color: #FFFFFF;
          }
        }
      }
    }
  }

  .toPassword {
    font-size: 14px;
    color: #fff;
    padding: 20px 6px;
    display: flex;
    justify-content: flex-end
  }

  .btnBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px 0;

    .btn {
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      color: #000000;
      padding: 10px 0;
      background: linear-gradient(180deg, #83E441 0%, #9AEF5E 100%);
      border-radius: 6px;
      text-align: center;
      margin-bottom: 10px;
    }

    .toRegister {
      font-weight: 500;
      font-size: 14px;
      color: #FFFFFF;
    }
  }
}
</style>
