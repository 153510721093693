<template>
  <div class="noticeDetailBox">
    <van-nav-bar :border="false" class="navBarBox" fixed left-arrow title="公告详情" @click-left="$router.back()"/>
    <div class="detailBox">
      <div class="title">{{ title }}</div>
      <div class="time">{{ time }}</div>
      <van-divider/>
      <div v-html="content"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: '',
      title: '',
      content: '',
      time: '',
    }
  },
  computed: {},
  watch: {},
  methods: {
    getInfo() {
      this.$post({
        url: `/app/notice/info`,
        data: {
          id: this.id
        },
        loading: true,
        success: (res) => {
          this.title = res.data.title
          this.content = res.data.content
          this.time = res.data.create_time
        }
      })
    },
  },
  created() {
    this.id = this.$route.query.id
    this.getInfo()
  },
  mounted() {
  },
}
</script>

<style lang="less" scoped>
.noticeDetailBox {
  min-height: 100vh;
  background-color: #000000;
  padding: 70px 15px 15px;

  ::v-deep(.navBarBox) {
    background-color: #000000;

    .van-icon {
      color: #FFFFFF;
    }

    .van-ellipsis {
      color: #FFFFFF;
    }

    .van-nav-bar__text {
      color: #FFFFFF;
    }
  }

  .detailBox {
    .title {
      color: #FFFFFF;
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .time {
      color: #FFFFFF;
      font-size: 13px;
    }
  }
}
</style>
