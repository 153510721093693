<template>
  <div class="expendRecordBox">
    <van-nav-bar :border="false" class="navBarBox" fixed left-arrow title="钱包记录" @click-left="$router.back()"/>
    <div class="listBox">
      <!--      <div class="tabsBox">-->
      <!--        <div v-for="(item,index) in tabs" :key="index" :class="{checkTab:item.id === check}" class="tabList" @click="cutTab(item.id)">{{ item.name }}</div>-->
      <!--      </div>-->
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div v-for="(item,index) in list" :key="index" class="list">
          <img alt="" class="img" src="@/assets/img/icon4.png">
          <div class="count">
            <p class="left">
              <span class="txt">{{ item.from_desc }}</span>
              <span class="time">{{ item.create_time }}</span>
            </p>
            <p class="right">{{ item.amount }}</p>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      tabs: [
        {id: 1, name: '提现'},
        {id: 2, name: '充值'},
      ],
      check: 1,
      page: 1,
      list: [],
    }
  },
  computed: {},
  watch: {},
  methods: {
    // onRefresh(){
    //   this.list = []
    //   this.finished = false
    //   this.loading = true
    //   this.onLoad()
    // },
    onLoad() {
      let page = this.page++
      this.$post({
        url: '/app/balance_log/balanceList',
        data: {
          page: page
        },
        success: (res) => {
          if (!res.data || res.data.data.length === 0) {
            console.log(111)
            this.finished = true
            this.loading = false

          } else {
            console.log(222)
            let list = []
            res.data.data.map((item) => {
              list.push(item)
            })
            this.list = [...this.list, ...list]
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        }
      })
    },
    cutTab(id) {
      this.check = id
      this.list = []
      this.finished = false
      this.loading = true
      this.onLoad()
    },
  },
  created() {
  },
  mounted() {
  },
}
</script>

<style lang="less" scoped>
.expendRecordBox {
  min-height: 100vh;
  background-color: #000000;

  ::v-deep(.navBarBox) {
    background-color: #000000;

    .van-icon {
      color: #FFFFFF;
    }

    .van-ellipsis {
      color: #FFFFFF;
    }

    .van-nav-bar__text {
      color: #FFFFFF;
    }
  }

  .listBox {
    padding: 60px 15px 30px;
    display: flex;
    flex-direction: column;
    gap: 15px 0;

    .tabsBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #343434;
      border-radius: 100px;
      border: 1px solid #FFFFFF82;

      .tabList {
        padding: 9px 42px;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;

        &.checkTab {
          background: linear-gradient(180deg, #83E441 0%, #9AEF5E 100%);
          color: #000000;
          border-radius: 20px;
        }
      }
    }

    //.van-pull-refresh {
    //  min-height: 200px;
    //}

    .list {
      display: flex;
      align-items: center;
      padding: 15px;
      background-color: #343434;
      border-radius: 15px;
      border: 1px solid #FFFFFF82;
      gap: 0 10px;
      margin-bottom: 15px;

      .img {
        width: 36px;
        height: 36px;
      }

      .count {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          flex-direction: column;
          gap: 0 10px;

          .txt {
            font-weight: 500;
            font-size: 13px;
            color: #FFFFFF;
          }

          .time {
            font-weight: 400;
            font-size: 12px;
            color: #BEBEBE;
          }
        }

        .right {
          font-weight: 400;
          font-size: 16px;
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>
