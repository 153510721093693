<template>
  <div class="noticeListBox">
    <van-nav-bar :border="false" class="navBarBox" fixed left-arrow title="公告列表" @click-left="$router.back()"/>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div v-for="(item,index) in list" :key="index" class="list" @click="$router.push(`noticeDetail?id=${item.id}`)">
        <!--        <img alt="" class="img" :src="item.image">-->
        <div class="count">
          <p class="left">
            <span class="txt">{{ item.title }}</span>
            <span class="time">{{ item.create_time }}</span>
          </p>
          <p class="right"></p>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      list: [],
      loading: false,
      finished: false
    }
  },
  computed: {},
  watch: {},
  methods: {
    onLoad() {
      let page = this.page++
      this.$post({
        url: '/app/notice/noticeList',
        data: {
          page: page
        },
        success: (res) => {
          if (!res.data || res.data.data.length === 0) {
            this.finished = true
            this.loading = false

          } else {
            let list = []
            res.data.data.map((item) => {
              list.push(item)
            })
            this.list = [...this.list, ...list]
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        }
      })
    },
  },
  created() {
  },
  mounted() {
  },
}
</script>

<style lang="less" scoped>
.noticeListBox {
  min-height: 100vh;
  background-color: #000000;
  padding: 70px 15px 15px;

  ::v-deep(.navBarBox) {
    background-color: #000000;

    .van-icon {
      color: #FFFFFF;
    }

    .van-ellipsis {
      color: #FFFFFF;
    }

    .van-nav-bar__text {
      color: #FFFFFF;
    }
  }

  .list {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #343434;
    border-radius: 15px;
    border: 1px solid #FFFFFF82;
    gap: 0 10px;
    margin-bottom: 15px;

    .img {
      width: 36px;
      height: 36px;
    }

    .count {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        flex-direction: column;
        gap: 0 10px;

        .txt {
          font-weight: 500;
          font-size: 13px;
          color: #FFFFFF;
        }

        .time {
          font-weight: 400;
          font-size: 12px;
          color: #BEBEBE;
        }
      }

      .right {
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
      }
    }
  }
}
</style>
