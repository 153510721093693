<template>
  <div class="rechargeBox">
    <van-nav-bar :border="false" class="navBarBox" fixed left-arrow right-text="充值记录" title="充值" @click-left="$router.back()" @click-right="$router.push('record?type=1')"/>
    <div class="recharge">
      <div class="qrCodeBox">
        <van-image :src="usdt_qrcode" class="qrImg"/>
      </div>
      <div class="txts">TRC20</div>
      <div class="rechargeAddress">
        <p class="txt">充值地址</p>
        <van-field v-model="usdt_address" class="fieldBox" readonly/>
        <p class="copy" @click="copyTxt(usdt_address)">复制地址</p>
      </div>
      <div class="uTxt">
        充值须知：<span v-html="recharge_info"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      usdt_qrcode: '',
      recharge_info: '',
      usdt_address: '',
    }
  },
  computed: {},
  watch: {},
  methods: {
    getUsdtAddress() {
      this.$post({
        url: `/app/member/getUsdtAddress`,
        loading: true,
        success: (res) => {
          this.recharge_info = res.data.recharge_info
          this.usdt_qrcode = res.data.usdt_qrcode
          this.usdt_address = res.data.usdt_address
        },
        tip: () => {
          setTimeout(() => {
            this.$router.push('payment')
          }, 2000)
        }
      })
    },
    copyTxt(code) {
      this.$copyText(code).then(e => {
        this.$toast('复制成功')
      })
    }
  },
  created() {
    this.getUsdtAddress()
  },
  mounted() {
  },
}
</script>

<style lang="less" scoped>
.rechargeBox {
  min-height: 100vh;
  background-color: #000000;

  ::v-deep(.navBarBox) {
    background-color: #000000;

    .van-icon {
      color: #FFFFFF;
    }

    .van-ellipsis {
      color: #FFFFFF;
    }

    .van-nav-bar__text {
      color: #FFFFFF;
    }
  }

  .recharge {
    padding: 100px 15px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .qrCodeBox {
      width: 242px;
      height: 242px;
      //margin-bottom: 54px;
      background-color: #343434;
      border-radius: 15px;
      border: 1px solid #707070;
      display: flex;
      align-items: center;
      justify-content: center;

      .qrImg {
        width: 204px;
        height: 204px;
      }
    }

    .txts {
      color: #FFFFFF;
      margin: 25px;
      font-size: 20px;
    }

    .rechargeAddress {
      width: 100%;
      margin-bottom: 44px;

      .txt {
        font-weight: bold;
        font-size: 16px;
        color: #FFFFFF;
        margin-bottom: 12px;
      }

      ::v-deep(.fieldBox) {
        background-color: #343434;
        border-radius: 15px;
        border: 1px solid #FFFFFF82;

        .van-field__control {
          color: #FFFFFF;
        }
      }

      .copy {
        margin-top: 15px;
        background-color: #999999;
        border-radius: 15px;
        padding: 10px 0;
        text-align: center;
        color: #FFFFFF;
      }
    }

    .uTxt {
      width: 100%;
      font-weight: 500;
      font-size: 13px;
      color: #999999;
    }
  }
}
</style>
