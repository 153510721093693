<template>
  <div class="paymentBox">
    <van-nav-bar :border="false" class="navBarBox" fixed left-arrow title="收款方式" @click-left="$router.back()"/>
    <div class="tabsBox">
      <div :class="{checkPayBox:checkPay===1}" class="payBox" @click="checkBtn(1)">
        <img alt="" class="payImg" src="@/assets/img/pay1.png">
      </div>
      <div :class="{checkPayBox:checkPay===2}" class="payBox" @click="checkBtn(2)">
        <img alt="" class="payImg" src="@/assets/img/pay2.png">
      </div>
      <div :class="{checkPayBox:checkPay===3}" class="payBox" @click="checkBtn(3)">
        <img alt="" class="payImg" src="@/assets/img/pay3.png">
      </div>
      <div :class="{checkPayBox:checkPay===4}" class="payBox" @click="checkBtn(4)">
        <img alt="" class="payImg" src="@/assets/img/pay4.png">
      </div>
    </div>
    <div class="title">{{ title[checkPay] }}信息</div>
    <div v-if="checkPay!==4" class="payFuncBox">
      <p class="topName">{{ title1[checkPay] }}</p>
      <van-field v-model="name" :border="false" :placeholder="placeholder1[checkPay]" class="fieldBox"/>
    </div>
    <div class="payFuncBox">
      <p class="topName">{{ title2[checkPay] }}</p>
      <van-field v-model="account_number" :border="false" :placeholder="placeholder2[checkPay]" class="fieldBox"/>
    </div>
    <div v-if="checkPay===3" class="payFuncBox">
      <p class="topName">开户行名称</p>
      <van-field v-model="bank" :border="false" class="fieldBox" placeholder="请输入开户行名称"/>
    </div>
    <div v-if="checkPay<=2" class="payFuncBox">
      <p class="topName">{{ title3[checkPay] }}</p>
      <van-uploader v-model="fileList" :after-read="afterRead" :max-count="1">
        <img alt="" class="uploadImg" src="@/assets/img/pay5.png"/>
      </van-uploader>
    </div>
    <div class="btn" @click="bindPay()">确定</div>
  </div>
</template>
<script>

import {Dialog} from "vant";

export default {
  data() {
    return {
      checkPay: 1,
      title: ['', '支付宝收款', '微信收款', '银行卡', '钱包地址'],
      title1: ['', '真实姓名', '真实姓名', '持卡人姓名', '钱包地址'],
      title2: ['', '支付宝账号', '微信号', '卡号', '钱包地址'],
      title3: ['', '上传支付宝收款码', '上传微信收款二维码'],
      placeholder1: ['', '请输入真实姓名', '请输入真实姓名', '请输入持卡人姓名', '请输入钱包地址'],
      placeholder2: ['', '请输入支付宝账号', '请输入微信号', '请输入银行卡号', '请输入钱包地址'],
      name: '',
      account_number: '',
      qrcode: '',
      bank: '',
      fileList: [],
      payData: {},
      checkPayTab: 'ali'
    }
  },
  computed: {},
  watch: {},
  methods: {
    getQueryBind() {
      this.$get({
        url: `/app/pay_collection/queryBind`,
        loading: true,
        success: (res) => {
          this.payData = res.data
          this.clearData()
          if (this.payData[this.checkPayTab].status == 1) {
            this.name = this.payData[this.checkPayTab].info.name
            this.account_number = this.payData[this.checkPayTab].info.account_number
            this.qrcode = this.payData[this.checkPayTab].info.qrcode
            this.bank = this.payData[this.checkPayTab].info.bank
            this.fileList.push({
              url: this.httpPath + this.payData[this.checkPayTab].info.qrcode
            })
          }
        },
        tip: () => {
        }
      })
    },
    afterRead(e) {
      let formData = new FormData()
      formData.append('image', e.file)
      this.$post({
        url: '/app/tools/upload',
        data: formData,
        upload: true,
        success: (res) => {
          this.qrcode = res.data.path
        },
        tip: () => {
        }
      })
    },
    checkBtn(id) {
      this.checkPay = id
      this.checkPayTab = ['', 'ali', 'wx', 'bank', 'wallet'][id]
      this.clearData()
      if (this.payData[this.checkPayTab].status == 1) {
        this.name = this.payData[this.checkPayTab].info.name
        this.account_number = this.payData[this.checkPayTab].info.account_number
        this.qrcode = this.payData[this.checkPayTab].info.qrcode
        this.bank = this.payData[this.checkPayTab].info.bank
        this.fileList.push({
          url: this.httpPath + this.payData[this.checkPayTab].info.qrcode
        })
      }
      if (id == 3) {
        Dialog.alert({
          // title: '温馨提示',
          message: '温馨提示：请绑定一类卡，且卡内余额不大于100元。',
          confirmButtonColor: '#000',
        }).then(() => {
          // on close
        });
      }
    },
    bindPay() {
      let data = {
        name: this.name,
        account_number: this.account_number,
        qrcode: this.qrcode,
        bank: this.bank,
        type: this.checkPay
      }
      this.$post({
        url: `/app/pay_collection/bind`,
        data,
        loading: true,
        success: (res) => {
          this.$toast(res.msg)
          setTimeout(() => {
            this.$router.back()
          }, 1500)
        },
        tip: () => {
        }
      })
      console.log(data)
    },
    clearData() {
      this.name = ''
      this.account_number = ''
      this.qrcode = ''
      this.bank = ''
      this.fileList = []
    },
  },
  created() {
    this.getQueryBind()
  },
  mounted() {
  },
}
</script>

<style lang="less" scoped>
.paymentBox {
  min-height: 100vh;
  padding: 50px 15px 30px;
  background-color: #000000;

  ::v-deep(.navBarBox) {
    background-color: #000000;

    .van-icon {
      color: #FFFFFF;
    }

    .van-ellipsis {
      color: #FFFFFF;
    }

    .van-nav-bar__text {
      color: #FFFFFF;
    }
  }

  .tabsBox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .payBox {
      padding: 20px;
      background-color: #343434;
      border-radius: 15px;
      border: 1px solid #343434;

      .payImg {
        width: 34px;
        height: 34px;
      }

      &.checkPayBox {
        border: 1px solid #89E749;
      }
    }
  }

  .title {
    margin: 45px 0 30px;
    font-weight: bold;
    font-size: 20px;
    color: #FFFFFF;
  }

  .payFuncBox {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    .topName {
      font-weight: bold;
      font-size: 15px;
      color: #999999;
    }

    ::v-deep(.fieldBox) {
      background-color: transparent;
      padding: 10px 0;
      border-bottom: 1px solid #EAEAEA;

      .van-field__control {
        color: #FFFFFF;
      }
    }

    ::v-deep(.van-uploader) {
      margin-top: 20px;

      .van-uploader__wrapper {
        justify-content: center;

        .uploadImg {
          width: 114px;
          height: 114px;
        }
      }
    }
  }

  .btn {
    margin-top: 30px;
    background: linear-gradient(180deg, #83E441 0%, #9AEF5E 100%);
    border-radius: 15px;
    padding: 15px 0;
    text-align: center;
  }
}
</style>
