<template>
  <div class="transferBox">
    <van-nav-bar :border="false" class="navBarBox" fixed left-arrow right-text="转账记录" title="转账" @click-right="$router.push('record?type=3')" @click-left="$router.back()"/>
    <div class="countBox">
      <!--      <div class="priceBox">-->
      <!--        <p class="txt1">可提现余额</p>-->
      <!--        <p class="price1">-->
      <!--          <span class="p1">1785977</span>-->
      <!--          <span class="p2">.000USDT</span>-->
      <!--        </p>-->
      <!--        <p class="p3">≈1.222CNY</p>-->
      <!--      </div>-->
      <div class="filedBox">
        <p class="txt">对方账号</p>
        <van-field v-model="phone" class="field" placeholder="请输入对方账号"/>
      </div>
      <div class="filedBox">
        <p class="txt">转账数量</p>
        <van-field v-model="amount" class="field" placeholder="请输入提现金额"/>
      </div>
      <div class="filedBox">
        <p class="txt">资金密码</p>
        <van-field v-model="password" class="field" placeholder="请输入资金密码" type="password"/>
      </div>
      <div class="cutBtn" @click="postTransferDo()">确定</div>
      <p class="text">转账须知：<span v-html="transfer_info"></span></p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone: '',
      amount: '',
      password: '',
      transfer_info: '',
    }
  },
  computed: {},
  watch: {},
  methods: {
    getInfo() {
      this.$post({
        url: `/app/withdraw/getInfo`,
        loading: true,
        success: (res) => {
          console.log(res)
          this.transfer_info = res.data.transfer_info
        },
        tip: () => {
        }
      })
    },
    postTransferDo() {
      if (!this.phone) return this.$toast('请输入对方账号')
      if (!this.amount) return this.$toast('请输入转账数量')
      if (!this.password) return this.$toast('请输入资金密码')
      let data = {
        phone: this.phone,
        amount: this.amount,
        password: this.password
      }
      this.$post({
        url: `/app/transfer/transferDo`,
        loading: true,
        data,
        success: (res) => {
          this.$toast(res.msg)
          this.phone = ''
          this.amount = ''
          this.password = ''
        },
        tip: () => {
        }
      })
    },
  },
  created() {
    this.getInfo()
  },
  mounted() {
  },
}
</script>

<style lang="less" scoped>
.transferBox {
  min-height: 100vh;
  background-color: #000000;

  ::v-deep(.navBarBox) {
    background-color: #000000;

    .van-icon {
      color: #FFFFFF;
    }

    .van-ellipsis {
      color: #FFFFFF;
    }

    .van-nav-bar__text {
      color: #FFFFFF;
    }
  }

  .countBox {
    padding: 50px 15px 30px;

    .filedBox {
      margin-top: 15px;
      display: flex;
      flex-direction: column;

      .txt {
        font-weight: bold;
        font-size: 16px;
        color: #FFFFFF;
        margin-bottom: 14px;
      }

      ::v-deep(.field) {
        background-color: #343434;
        border-radius: 15px;
        border: 1px solid #FFFFFF82;

        .van-field__control {
          color: #FFFFFF;
        }

        .txt {
          font-weight: 500;
          font-size: 14px;
          color: #89E94B;
        }
      }
    }

    .cutBtn {
      background: linear-gradient(180deg, #83E441 0%, #9AEF5E 100%);
      border-radius: 15px;
      padding: 15px 0;
      text-align: center;
      font-weight: 500;
      font-size: 15px;
      color: #000000;
      margin: 62px 0 40px;
    }

    .text {
      font-weight: 500;
      font-size: 13px;
      color: #999999;
    }
  }
}
</style>
