<template>
  <div class='indexbox'>
    <nav-bar title="记录a" left-arrow bg-color="red" left-color="#ffffff" title-color="#fff" />
    <page-list class="listbox" api="/app/c2c/trade_list" :offset="0" @changelist="(data) => { [...list, ...data] }" @refresh="() => { list = [] }">
      <div class="items" v-for="val in list" :key="val.num">
        <div class="leftcon">
          <p>{{ val.name }}</p>
          <p>2022-12-02 11:11:11</p>
        </div>
        <p class="rightcon">+{{ val.num }}</p>
      </div>
    </page-list>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        { name: "你好", num: 1 },
        { name: "你好", num: 2 },
        { name: "你好", num: 3 },
        { name: "你好", num: 4 },
        { name: "你好", num: 5 },
        { name: "你好", num: 6 },
        { name: "你好", num: 7 },
        { name: "你好", num: 8 },
        { name: "你好", num: 9 },
        { name: "你好", num: 10 },
        { name: "你好", num: 11 },
        { name: "你好", num: 12 },
        { name: "你好", num: 13 },
        { name: "你好", num: 14 },
        { name: "你好", num: 15 },
        { name: "你好", num: 16 },
        { name: "你好", num: 17 },
        { name: "你好", num: 18 },
        { name: "你好", num: 19 },
        { name: "你好", num: 20 },
      ],
      name: "",
      key: "",
    }
  },
  computed: {},
  watch: {},
  methods: {},
  created() {
    this.setUrlData()
    console.log(this.$data)
  },
  mounted() { },
}
</script>
<style lang='less' scoped>
.indexbox {
  width: 100%;
  min-height: 100vh;
  background-color: #333749;
  .listbox {
    width: 100%;
    padding: 8px 15px;
    .items {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fff;
      padding: 8px 10px;
      border-radius: 10px;
      margin-bottom: 10px;
      .leftcon {
        width: 49%;
        color: #000;
        font-size: 12px;
        p:nth-child(1) {
          font-size: 14px;
        }
      }
      .rightcon {
        width: 49%;
        text-align: right;
        font-size: 16px;
        color: @theme;
        font-weight: 600;
      }
    }
  }
}
</style>