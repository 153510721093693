<template>
  <div class="alertPasswordBox">
    <van-nav-bar :border="false" :title="title[type]" class="navBarBox" fixed left-arrow @click-left="$router.back()"/>
    <div class="mobileBox">手机号：{{ phone }}</div>
    <div class="fieldBox">
      <van-field v-model="sms_code" :border="false" class="field" label="验证码" placeholder="请输入验证码">
        <template #button>
          <span class="fieldBtn" @click="sendSms()">{{ sendSmsShow ? `${sendTime}秒后发送` : `发送验证码` }}</span>
        </template>
      </van-field>
      <van-field v-model="password" :border="false" class="field" label="新密码" placeholder="请输入新密码" type="password"/>
      <van-field v-model="re_password" :border="false" class="field" label="确认新密码" placeholder="请再次输入新密码" type="password"/>
      <div class="btn" @click="editFunc()">确定</div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      title: ['', '修改支付密码', '修改登录密码'],
      type: 1,
      sms_code: '',
      password: '',
      re_password: '',
      phone: '',
      path: '',
      sendSmsShow: false,
      sendTime: 60,
      inter: null
    }
  },
  computed: {},
  watch: {},
  methods: {
    getMemberInfo() {
      this.$post({
        url: '/app/member/memberInfo',
        loading: true,
        success: (res) => {
          this.phone = res.data.phone
        },
        tip: () => {
        }
      })
    },
    sendSms() {
      if (this.sendSmsShow) return this.$toast(`${this.sendTime}秒后重新获取`)
      this.$post({
        url: `/app/member/sendSms`,
        loading: true,
        data: {
          scene: 'edit_password'
        },
        success: (res) => {
          this.sendSmsShow = true
          this.inter = setInterval(() => {
            if (this.sendTime > 0) {
              this.sendTime--
            } else {
              clearInterval(this.inter)
              this.inter = null
              this.sendSmsShow = false
              this.sendTime = 60
            }
          }, 1000)
          console.log(res)
        },
        tip: () => {
        }
      })
    },
    editFunc() {
      if (!this.sms_code) return this.$toast('请输入验证码')
      if (!this.password) return this.$toast('请输入新密码')
      if (!this.re_password) return this.$toast('请再次输入新密码')
      let data = {
        sms_code: this.sms_code,
        password: this.password,
        re_password: this.re_password
      }
      console.log(data, this.path)
      this.$post({
        url: this.path,
        loading: true,
        data,
        success: (res) => {
          this.$toast(res.msg)
          setTimeout(() => {
            this.$router.back()
          }, 1500)
        },
        tip: () => {
        }
      })
    },
  },
  created() {
    this.type = this.$route.query.type
    this.path = ['', '/app/member/editSecondPassword', '/app/member/editPassword'][this.type]
    this.getMemberInfo()
  },
  mounted() {

  },
}
</script>

<style lang="less" scoped>
.alertPasswordBox {
  min-height: 100vh;
  padding: 50px 0 15px;
  background-color: #000000;

  ::v-deep(.navBarBox) {
    background-color: #000000;

    .van-icon {
      color: #FFFFFF;
    }

    .van-ellipsis {
      color: #FFFFFF;
    }

    .van-nav-bar__text {
      color: #FFFFFF;
    }
  }

  .mobileBox {
    padding: 15px 30px;
    font-weight: bold;
    font-size: 15px;
    color: #FFFFFF;
    background-image: linear-gradient(to bottom, #315618, #33571b, #36581e, #385920, #3a5a23);
  }

  .fieldBox {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px 0;

    ::v-deep(.field) {
      background: #343434;
      border-radius: 8px;
      border: 1px solid #FFFFFF7F;

      .fieldBtn {
        padding: 8px 7px;
        background-color: #000000;
        border-radius: 4px;
        font-weight: 500;
        font-size: 12px;
        color: #FFFFFF;
      }

      .van-field__label {
        color: #FFFFFF;
      }

      .van-field__value {
        .van-field__control {
          color: #FFFFFF;
        }
      }
    }

    .btn {
      background: linear-gradient(180deg, #83E441 0%, #9AEF5E 100%);
      border-radius: 15px;
      font-weight: 500;
      font-size: 15px;
      color: #000000;
      padding: 15px 0;
      margin-top: 100px;
      text-align: center;
    }
  }
}
</style>
