<template>
  <div class="recordBox">
    <van-nav-bar :border="false" :title="title" class="navBarBox" fixed left-arrow @click-left="$router.back()"/>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="listBox">
        <div v-for="(item,index) in list" :key="index" class="list">
          <div v-if="type==2" class="topTxt">
            <span class="left">提现至{{ ['', '支付宝', '微信', '银行卡', '钱包'][item.type] }}</span>
            <span class="right">{{ ['待审核', '已通过', '已驳回'][item.status] }}</span>
          </div>
          <div class="btmTxt">
            <img alt="" class="img" src="@/assets/img/icon4.png">
            <div class="count">
              <p class="left">
                <span class="txt">{{ `${text}${item?.to_nickname ? item.to_nickname : ''}` }}</span>
                <span class="time">{{ item.create_time }}</span>
              </p>
              <p class="right">{{ item.amount }}</p>
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: '记录',
      path: '',
      type: 0,
      loading: false,
      finished: false,
      page: 1,
      list: [],
      text: '',
    }
  },
  computed: {},
  watch: {},
  methods: {
    onLoad() {
      let page = this.page++
      this.$get({
        url: this.path,
        data: {
          page: page
        },
        success: (res) => {
          if (!res.data || res.data.data.length === 0) {
            this.finished = true
            this.loading = false
          } else {
            let list = []
            res.data.data.map((item) => {
              list.push(item)
            })
            this.list = [...this.list, ...list]
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        }
      })
    },
  },
  created() {
    this.type = this.$route.query.type
    this.title = ['记录', '充值记录', '提现记录', '转账记录'][this.type]
    this.path = ['', '/app/balance_log/chargeList', '/app/withdraw/withdrawList', '/app/transfer/transferList'][this.type]
    this.text = ['', '充值', '提现', '转给'][this.type]
  },
  mounted() {
  },
}
</script>

<style lang="less" scoped>
.recordBox {
  min-height: 100vh;
  background-color: #000000;

  ::v-deep(.navBarBox) {
    background-color: #000000;

    .van-icon {
      color: #FFFFFF;
    }

    .van-ellipsis {
      color: #FFFFFF;
    }

    .van-nav-bar__text {
      color: #FFFFFF;
    }
  }

  .listBox {
    padding: 60px 15px 30px;
    display: flex;
    flex-direction: column;
    gap: 15px 0;

    .list {
      padding: 15px;
      background-color: #343434;
      border-radius: 15px;
      border: 1px solid #FFFFFF82;

      .topTxt {
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
      }

      .btmTxt {
        display: flex;
        align-items: center;
        gap: 0 10px;

        .img {
          width: 36px;
          height: 36px;
        }

        .count {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .left {
            display: flex;
            flex-direction: column;
            gap: 0 10px;

            .txt {
              font-weight: 500;
              font-size: 13px;
              color: #FFFFFF;
            }

            .time {
              font-weight: 400;
              font-size: 12px;
              color: #BEBEBE;
            }
          }

          .right {
            font-weight: 400;
            font-size: 16px;
            color: #FFFFFF;
          }
        }
      }
    }
  }
}
</style>
