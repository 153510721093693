<template>
  <div class="teamBox">
    <van-nav-bar :border="false" class="navBarBox" fixed left-arrow title="团队" @click-left="$router.back()"/>
    <div class="topBox">
      <div class="left">
        <p class="top">团队人数</p>
        <p class="btm">{{ total }}</p>
      </div>
      <img alt="" class="rightImg" src="@/assets/img/team1.png">
    </div>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="listBox">
        <p class="title">直推列表</p>
        <div class="lists">
          <div v-for="(item,index) in list" :key="index" class="list">
            <p class="top">
              <img :src="httpPath+item.head_img" alt="" class="img">
              <span class="name">{{ item.nickname }}</span>
            </p>
            <p class="count">
              <span class="left">总入金：{{ item.in }}</span>
              <span class="right">剩余：{{ item.balance }}</span>
            </p>
            <p class="btm">
              <span class="left">推荐时间</span>
              <span class="time">{{ item.create_time }}</span>
            </p>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
export default {
  data() {
    return {
      total: 0,
      loading: false,
      finished: false,
      page: 1,
      list: [],
    }
  },
  computed: {},
  watch: {},
  methods: {
    getTeamTotal() {
      this.$post({
        url: '/app/member/teamTotal',
        loading: true,
        success: (res) => {
          this.total = res.data.total
          this.onLoad()
        },
        tip: () => {
        }
      })
    },
    onLoad() {
      let page = this.page++
      this.$get({
        url: '/app/member/teamList',
        data: {
          page: page
        },
        success: (res) => {
          if (!res.data || res.data.data.length === 0) {
            this.finished = true
            this.loading = false
          } else {
            let list = []
            res.data.data.map((item) => {
              list.push(item)
            })
            this.list = [...this.list, ...list]
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        }
      })
    },
  },
  created() {
    this.getTeamTotal()
  },
  mounted() {
  },
}
</script>

<style lang="less" scoped>
.teamBox {
  min-height: 100vh;
  padding: 50px 0 15px;
  background-color: #000000;

  ::v-deep(.navBarBox) {
    background-color: #000000;

    .van-icon {
      color: #FFFFFF;
    }

    .van-ellipsis {
      color: #FFFFFF;
    }

    .van-nav-bar__text {
      color: #FFFFFF;
    }
  }

  .topBox {
    padding: 12px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(to bottom, #5c6d3b, #4b704f, #437161, #476f6d, #546d72);

    .left {
      color: #FFFFFF;
      margin-left: 15px;

      .top {
        font-weight: 500;
        font-size: 13px;
      }

      .btm {
        font-weight: bold;
        font-size: 30px;
      }
    }

    .rightImg {
      width: 156px;
      height: 106px;
    }
  }

  .listBox {
    padding: 15px;

    .title {
      font-weight: bold;
      font-size: 16px;
      color: #FFFFFF;
    }

    .lists {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      gap: 15px 0;

      .list {
        padding: 15px;
        background: #343434;
        border-radius: 8px;
        border: 1px solid #FFFFFF7F;

        .top {
          display: flex;
          align-items: center;
          gap: 0 10px;

          .img {
            width: 38px;
            height: 38px;
            border-radius: 50%;
          }

          .name {
            font-weight: 500;
            font-size: 15px;
            color: #FFFFFF;
          }
        }

        .count {
          display: flex;
          align-items: center;
          gap: 0 20px;
          color: #FFFFFF;
          margin: 10px 0;
        }

        .btm {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 500;
          font-size: 13px;
          color: #999999;
        }
      }
    }
  }
}
</style>
