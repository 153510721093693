<template>
  <div class="mineNewsBox">
    <van-nav-bar :border="false" class="navBarBox" fixed left-arrow title="个人信息" @click-left="$router.back()" />
    <div class="listBox">
      <div class="list">
        <span class="name">头像</span>
        <uploader :after-read="afterRead">
          <van-image round width="8vw" height="8vw" :src="$img(head_img)" />

        </uploader>
      </div>
      <div class="list" @click="router('nickname')">
        <span class="name">昵称</span>
        <span class="right">{{ nickname }}</span>
      </div>
      <div class="list">
        <span class="name">手机号</span>
        <span class="right">{{ phone }}</span>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      head_img: '',
      nickname: '',
      phone: '',
    }
  },
  created() {
    this.get_info()
  },
  methods: {
    get_info() {
      this.$post({
        url: "/app/member/memberInfo",
        loading: true,
        data: {
        },
        success: (res) => {
          this.head_img = res.data.head_img
          this.nickname = res.data.nickname
          this.phone = res.data.phone

          console.log(res.data)
        },
        tip: () => {

        },
      })
    },
    $img(url) {
      return this.httpPath + url
    },
    afterRead(file) {
      // 上传图片
      let formData = new FormData()
      formData.append("image", file.file)
      this.$post({
        url: "/app/member/editHead",
        data: formData,
        upload: true,
        success: (res) => {

          this.head_img = res.data.path
        },
      })
    }
  }
}
</script>

<style lang="less" scoped>
.mineNewsBox {
  min-height: 100vh;
  padding: 50px 15px 15px;
  background-color: #000000;

  ::v-deep(.navBarBox) {
    background-color: #000000;

    .van-icon {
      color: #FFFFFF;
    }

    .van-ellipsis {
      color: #FFFFFF;
    }

    .van-nav-bar__text {
      color: #FFFFFF;
    }
  }

  .listBox {
    display: flex;
    flex-direction: column;
    gap: 10px 0;

    .list {
      padding: 15px;
      background-color: #343434;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 8px;

      .name {
        font-weight: 500;
        font-size: 15px;
        color: #FFFFFF;
      }

      .right {
        font-weight: 500;
        font-size: 14px;
        color: #999999;
      }

      ::v-deep(.van-uploader) {
        border-radius: 50%;

        //overflow: hidden;
        .van-uploader__upload {
          margin: 0;
        }

        .van-uploader__preview {
          margin: 0;
        }
      }
    }
  }
}
</style>
