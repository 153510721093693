<template>
  <div class="alertPasswordBox">
    <nav-bar title="忘记密码" left-arrow left-color="#fff" @click-left="$router.go(-1)" :placeholder="false" bg-color="" />

    <div class="fieldBox">
      <van-field v-model="phone" type="tel" class="field" maxlength="11" clearable label="手机号" placeholder="请输入手机账号" />
      <van-field v-model="sms_code" type="text" class="field" maxlength="11" clearable label="验证码" placeholder="请输入验证码">
        <template #button>
          <send-code class="code" api="/app/auth/sendSms" :send-tell="{ 'phone': phone }"
            :send-scene="{ 'scene': 'forget_password' }" :no-token="true" color="#83E441" round>
          </send-code>
        </template>
      </van-field>
      <van-field v-model="password" class="field" clearable type="password" placeholder="请输入登录密码" label="新密码" />
      <van-field v-model="re_password" class="field" clearable type="password" placeholder="请输入确认登录密码" label="重复密码" />

      <div class="btn" @click="registBtn">确定</div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      /** 手机号 */
      phone: '',
      /** 短信验证码 */
      sms_code: '',
      /** 密码 */
      password: '',
      /** 确认密码 */
      re_password: '',
      /** 提交按钮禁用防止连点 */
      disabled: false,
    }
  },
  created() { },
  methods: {
    registBtn() {
      if (!this.phone) return this.$toast("请输入手机号")
      if (!/^1[23456789]\d{9}$/.test(this.phone)) return this.$toast("请输入正确格式的手机号")
      if (!this.sms_code) return this.$toast("请输入验证码")
      if (!this.password) return this.$toast("请输入登录密码")
      if (!this.re_password) return this.$toast("请输入确认登录密码")
      if (this.password !== this.re_password) return this.$toast("两次输入的登录密码不一致")

      if (this.disabled) return
      this.disabled = true

      this.$post({
        url: "/app/auth/forgetPassword",
        loading: true,
        noToken: true,
        data: {
          phone: this.phone,
          sms_code: this.sms_code,
          password: this.password,
          re_password: this.re_password,
        },
        success: () => {
          this.toast({
            message: "修改成功",
            onClose: () => {
              this.$router.replace("login")
              setTimeout(() => {
                this.disabled = false
              }, 1500)
            },
          })

        },
        tip: () => {
          setTimeout(() => {
            this.disabled = false
          }, 1500)
        },
      })
    },
  }
}
</script>

<style lang="less" scoped>
.alertPasswordBox {
  min-height: 100vh;
  padding: 50px 0 15px;
  background-color: #000000;

  ::v-deep(.van-nav-bar) {
    background-color: #000000;

    .van-icon {
      color: #FFFFFF !important;
    }

    .van-ellipsis {
      color: #FFFFFF !important;
    }

    .van-nav-bar__text {
      color: #FFFFFF !important;
    }
  }

  .mobileBox {
    padding: 15px 30px;
    font-weight: bold;
    font-size: 15px;
    color: #FFFFFF;
    background-image: linear-gradient(to bottom, #315618, #33571b, #36581e, #385920, #3a5a23);
  }

  .fieldBox {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px 0;

    ::v-deep(.field) {
      background: #343434;
      border-radius: 8px;
      border: 1px solid #FFFFFF7F;

      .van-field__label {
        color: #FFFFFF;
      }

      .van-field__value {
        .van-field__control {
          color: #FFFFFF;
        }
      }
    }

    :deep(.code) {
      // margin-right: -15px;
      margin-top: -4px;

      .van-button__content {
        color: #000 !important;
      }

      .van-button--mini {
        padding: 0 10px;
      }

    }

    .btn {
      background: linear-gradient(180deg, #83E441 0%, #9AEF5E 100%);
      border-radius: 6px;
      font-weight: 500;
      font-size: 14px;
      color: #000000;
      padding: 10px 0;
      margin-top: 100px;
      text-align: center;
    }
  }
}
</style>
