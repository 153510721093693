<template>
  <div class="shareBox">
    <van-nav-bar :border="false" class="navBarBox" fixed left-arrow title="推广二维码" @click-left="$router.back()"/>
    <div class="shareMsgBox">
      <img alt="" class="img1" src="@/assets/img/share1.png">
      <div class="msgBox">
        <div class="left">
          <p class="txt">邀请码</p>
          <p class="code">
            <span class="num">{{ invite_code }}</span>
            <span class="btn" @click="copyTxt(invite_code)">复制</span>
          </p>
        </div>
        <div class="qrcode">
          <img id="qrImg" :src="img" alt="" class="qrImg">
        </div>
      </div>
      <div class="saveBtn" @click="saveImg">保存图片</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      invite_code: '',
      img: '',
    }
  },
  computed: {},
  watch: {},
  methods: {
    getShare() {
      this.$post({
        url: `/app/member/share`,
        loading: true,
        success: (res) => {
          this.invite_code = res.data.invite_code
          this.img = res.data.img
        },
        tip: () => {
        }
      })
    },
    saveImg() {
      let img = document.getElementById('qrImg')
      let url = img.src
      let a = document.createElement('a')
      let event = new MouseEvent('click')
      a.download = '分享二维码'
      a.href = url
      a.dispatchEvent(event)
    },
    copyTxt(code) {
      this.$copyText(code).then(e => {
        this.$toast('复制成功')
      })
    }
  },
  created() {
    this.getShare()
  },
  mounted() {
  },
}
</script>

<style lang="less" scoped>
.shareBox {
  min-height: 100vh;
  padding: 50px 15px 15px;
  background-image: url("../../../assets/img/shareBg.png");
  background-repeat: no-repeat;
  background-size: cover;

  ::v-deep(.navBarBox) {
    background-color: transparent;

    .van-icon {
      color: #FFFFFF;
    }

    .van-ellipsis {
      color: #FFFFFF;
    }

    .van-nav-bar__text {
      color: #FFFFFF;
    }
  }

  .shareMsgBox {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 15px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .img1 {
      width: 108px;
      height: 28px;
    }

    .msgBox {
      padding: 32px 50px;
      width: 100%;
      border-radius: 15px;
      background-image: linear-gradient(to right bottom, #343956, #393853, #3d3650, #40354d, #43344a);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        .txt {
          font-weight: 500;
          font-size: 15px;
          color: #FFFFFF;
        }

        .code {
          display: flex;
          align-items: center;
          color: #FFFFFF;
          gap: 0 5px;

          .num {
            font-weight: bold;
            font-size: 20px;
          }

          .btn {
            font-weight: 500;
            font-size: 12px;
            padding: 2px 8px;
            background-color: #000000;
            border-radius: 4px;
          }
        }
      }

      .qrcode {
        width: 82px;
        height: 82px;
        background-color: #FFFFFF;

        .qrImg {
          width: 82px;
          height: 82px;
        }
      }
    }

    .saveBtn {
      width: 100%;
      text-align: center;
      font-weight: 500;
      font-size: 15px;
      color: #000000;
      padding: 15px 0;
      background: linear-gradient(180deg, #83E441 0%, #9AEF5E 100%);
      border-radius: 15px;
      margin-top: 35px;
    }
  }
}
</style>
