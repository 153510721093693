<template>
  <div class="download">
    <nav-bar title="下载APP" left-arrow left-color="#fff" @click-left="$router.go(-1)" :placeholder="false" bg-color="" />
    <div v-if="!isWeixin">
      <div class="header">
        <div class="logobox">
          <img src="../../../assets/img/logo.png" alt="" />
        </div>
      </div>
      <div class="mainbox">
        <van-button icon="down" type="info" @click="download(data.url)">下载安装</van-button>
        <p class="tit">适用于安卓手机</p>
        <div class="qrcodes">
          <qr-code :text="data.url" :size="160" />
        </div>
        <p class="tit" v-if="data.url">扫描二维码下载APP</p>
      </div>
    </div>
    <div v-if="isWeixin">
      <img src="../../../assets/img/down.png" alt="" style="width: 100vw; height: 100vh" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {},
      text: "1212",
      isWeixin: false,
    }
  },
  created() {
    this.getdata()
  },
  mounted() {
    this.isWeixin = /micromessenger/.test(window.navigator.userAgent.toLowerCase())
  },
  methods: {
    getdata() {
      this.$get({
        url: "/app/auth/downloadUrl",
        success: (res) => {
          this.$toast.clear()
          this.data = res.data
        },
        tip: () => { },
      })
    },
    download(href) {
      // window.open(href)

      // 创建隐藏的可下载链接
      var eleLink = document.createElement("a")
      eleLink.setAttribute("href", href)
      eleLink.setAttribute("download", name)
      eleLink.style.display = "none"
      // 触发点击
      document.body.appendChild(eleLink)
      eleLink.click()
      // 然后移除
      document.body.removeChild(eleLink)
    },
  },
}
</script>

<style lang="less" scoped>
.download {
  width: 100%;
  min-height: 100vh;
  background: #000;

  ::v-deep(.van-nav-bar) {
    background-color: #000000;

    .van-icon {
      color: #FFFFFF !important;
    }

    .van-ellipsis {
      color: #FFFFFF !important;
    }

    .van-nav-bar__text {
      color: #FFFFFF !important;
    }
  }


  .header {
    width: 100%;
    padding: 120px 0 10px;
    background: #000;
    background-size: 100% 80%;
    display: flex;
    justify-content: center;
    align-items: center;

    .logobox {
      width: 150px;
      height: 150px;
      box-shadow: 0px 3px 6px rgba(6, 101, 228, 0.08);
      background: #ffffff;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px;

      >img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .mainbox {
    width: 100%;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /deep/.van-button {
      width: 40%;
      margin: 30px 0;
      border-radius: 10px;
    }

    .tit {
      font-size: 14px;
      color: #fff;
      margin-bottom: 10px;
    }

    .qrcodes {
      padding: 10px;
      background: #fff;
      margin-bottom: 10px;
    }
  }
}
</style>
