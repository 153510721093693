<template>
  <div class="indexBox">
    <div class="topBox">
      <div class="text1">Club</div>
      <div class="text2">总资产</div>
      <div class="text3">
        <div class="left">{{ getPrice(info.balance)[0] }}.</div>
        <div class="right">{{ getPrice(info.balance)[1] }}</div>
      </div>
      <div class="gridBox">
        <div class="btnBox box1" @click="router('recharge')">
          <img alt="" class="img" src="@/assets/img/icon1.png">
          <span class="txt">充值</span>
        </div>
        <div class="btnBox" @click="router('withdrawal')">
          <img alt="" class="img" src="@/assets/img/icon2.png">
          <span class="txt">提现</span>
        </div>
        <div class="btnBox box2" @click="router('transfer')">
          <img alt="" class="img" src="@/assets/img/icon3.png">
          <span class="txt">转账</span>
        </div>
      </div>
    </div>
    <div class="noticeBox">
      <img alt="" class="img" src="@/assets/img/icon6.png">
      <van-notice-bar :scrollable="false" class="noticeBar" color="#FFFFFF">
        <van-swipe :autoplay="3000" :show-indicators="false" class="noticeSwipe" vertical>
          <van-swipe-item v-for="(item,index) in noticeList" :key="index" @click="$router.push(`noticeDetail?id=${item.id}`)">{{ item.title }}</van-swipe-item>
        </van-swipe>
      </van-notice-bar>
      <div class="right" @click="router('noticeList')">
        <span class="txt">更多</span>
        <img alt="" class="allImg" src="@/assets/img/icon7.png">
      </div>
    </div>
    <!--    <div class="listBox">-->
    <!--      <div class="tabBox">-->
    <!--        <span class="topTxt">余额</span>-->
    <!--        <div class="price">-->
    <!--          <span class="p1">17732</span>-->
    <!--          <span class="p2">.098</span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="tabBox">-->
    <!--        <span class="topTxt">余额</span>-->
    <!--        <div class="price">-->
    <!--          <span class="p1">17732</span>-->
    <!--          <span class="p2">.098</span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="expList">
      <div class="expListBox">
        <p class="topTxt">
          <span class="left">
            <img alt="" class="leftImg" src="@/assets/img/icon5.png">
            <span class="txt">最近钱包记录</span>
          </span>
          <span class="right" @click="router('expendRecord')">
            <span class="txt">查看更多</span>
            <img alt="" class="allImg" src="@/assets/img/icon7.png">
          </span>
        </p>
        <div class="recordListBox">
          <p v-for="(item,index) in balanceList" :key="index" class="list">
            <img alt="" class="leftImg" src="@/assets/img/icon4.png">
            <span class="count">
              <span class="leftC">
                <span class="top">{{ item.from_desc }}</span>
                <span class="time">{{ item.create_time }}</span>
              </span>
              <span class="price">{{ item.amount }}</span>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getPrice} from "@/plugins/func";

export default {
  props: {},
  data() {
    return {
      noticeList: [],
      info: {},
      balanceList: [],
    }
  },
  created() {
    this.getMemberInfo()
  },
  methods: {
    getPrice,
    getMemberInfo() {
      this.$post({
        url: `/app/member/memberInfo`,
        loading: true,
        success: (res) => {
          this.info = res.data
          this.getNoticeList()
        }
      })
    },
    getNoticeList() {
      this.$post({
        url: '/app/notice/noticeList',
        loading: true,
        success: (res) => {
          this.noticeList = res.data.data
          this.getBalanceList()
        }
      })
    },
    getBalanceList() {
      this.$post({
        url: `/app/balance_log/balanceList`,
        data: {
          page: 1,
          type: 1,
        },
        loading: true,
        success: (res) => {
          this.balanceList = res.data.data.slice(0, 3)
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.indexBox {
  width: 100%;
  min-height: 100vh;
  background-color: #000000;

  .topBox {
    background: linear-gradient(180deg, #CEF384 0%, #BCF3FF 100%);
    border-radius: 0 0 25px 25px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .text1 {
      font-weight: bold;
      font-size: 16px;
      color: #000000;
      margin-bottom: 36px;
    }

    .text2 {
      font-weight: 500;
      font-size: 13px;
      color: #333333;
      margin-bottom: 13px;
    }

    .text3 {
      font-weight: bold;
      font-size: 26px;
      color: #000000;
      display: flex;
      align-items: baseline;
      margin-bottom: 29px;

      .right {
        font-size: 22px;
      }
    }

    .gridBox {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btnBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #010103;
        padding: 10px 39px;
        border-radius: 4px;

        .img {
          width: 18px;
          height: 18px;
        }

        .txt {
          font-weight: 500;
          font-size: 13px;
          color: #FFFFFF;
        }
      }

      .box1 {
        border-radius: 4px 4px 4px 25px;
      }

      .box2 {
        border-radius: 4px 4px 25px 4px;
      }
    }
  }

  .noticeBox {
    display: flex;
    align-items: center;
    padding: 0 13px;

    .img {
      width: 18px;
      height: 18px;
    }

    .noticeBar {
      width: 100%;
      background-color: #000000;

      .noticeSwipe {
        height: 40px;
        line-height: 40px;
      }
    }

    .right {
      display: flex;
      align-items: center;
      width: 60px;
      gap: 0 5px;

      .txt {
        font-weight: 500;
        font-size: 12px;
        color: #C9C9C9;
      }

      .allImg {
        width: 10px;
        height: 10px;
      }
    }
  }

  .listBox {
    padding: 0 15px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tabBox {
      background-color: #343434;
      padding: 15px 17px;
      width: 160px;
      border-radius: 15px;

      .topTxt {
        font-weight: 500;
        font-size: 13px;
        color: #FFFFFF;
      }

      .price {
        display: flex;
        align-items: baseline;
        font-weight: bold;
        color: #FFFFFF;

        .p1 {
          font-size: 20px;
        }

        .p2 {
          font-size: 14px;
        }
      }
    }
  }

  .expList {
    padding: 0 15px 70px;

    .expListBox {
      //min-height: 300px;
      max-height: 300px;
      padding: 13px 15px 15px;
      border-radius: 15px;
      background-image: url("../../assets/img/iconBg1.png");
      background-repeat: no-repeat;
      background-size: cover;

      .topTxt {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;
          gap: 0 7px;

          .leftImg {
            width: 18px;
            height: 18px;
          }

          .txt {
            font-weight: bold;
            font-size: 16px;
            color: #FFFFFF;
          }
        }

        .right {
          display: flex;
          align-items: center;
          gap: 0 5px;

          .txt {
            font-weight: 500;
            font-size: 12px;
            color: #FFFFFF;
          }

          .allImg {
            width: 12px;
            height: 12px;
          }
        }
      }

      .recordListBox {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px 0;

        .list {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 0 10px;
          padding: 15px;
          border: 1px solid #FFFFFF;
          border-radius: 15px;

          .leftImg {
            width: 36px;
            height: 36px;
          }

          .count {
            display: flex;
            align-items: center;
            width: 100%;
            color: #FFFFFF;
            justify-content: space-between;

            .leftC {
              display: flex;
              flex-direction: column;

              .top {
                font-weight: 500;
                font-size: 13px;
              }

              .time {
                font-weight: 400;
                font-size: 12px;
                color: #BEBEBE;
              }
            }

            .price {
              font-weight: 400;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
